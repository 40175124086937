.games {
  > .container {
    @include media-breakpoint-up(sm) {
      height: 100vh;
    }
  }
  .game {
    position: relative;
    height: 401px;
    width: 250px;
    max-width: 250px;
    border-radius: 10px;
    margin: 10px;

    @include media-breakpoint-down(md) {
      width: 242px;
      max-width: 242px;
      height: 242px;

      &.done {
        height: 180px;
      }
    }

    //margin: 0 10px;
    /*     &.first {
      background: url(/assets/images/_jub/games-bg-game-1.svg);
      //background-clip: content-box;
      background-size: cover;
      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    } */
    /*     &.second {
      background: url(/assets/images/_jub/games-bg-game-2.svg);
      //background-clip: content-box;
      background-size: 100%;
      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    }
    &.third {
      background: url(/assets/images/_jub/games-bg-game-3.svg);
      //background-clip: content-box;
      background-size: 100%;
      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    } */

    @include media-breakpoint-up(sm) {
      &.first {
        .complete {
          right: -20px;
        }
      }

      &.second {
        .complete {
          right: 0;
        }
      }

      &.third {
        .complete {
          right: 23px;
        }
      }
    }

    .content {
      height: 100%;
    }

    .complete {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      right: 0;
      opacity: 1;
    }

    .active {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 10px;
      //height: 100%;
      @include media-breakpoint-down(md) {
        width: 100%;
        //height: 265px;
      }
    }
  }

  /*   svg {
    z-index: 1;
    position: relative;
  }

  svg.inactive {
    transform: scale(0.75);
  }
  .inactive_link {
    //opacity: 0.7;
    pointer-events: none;
  }

  .active {
    transition: all 0.5s ease;
    animation: floatWithShadow 6s ease-in-out infinite;
    animation-delay: 0.5s;
    filter: drop-shadow(0px 0px 41px #e4c418);
    #kljuc {
      transform-origin: center;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      transform: scale(1.1);
      animation-play-state: paused;
      filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
      #kljuc {
        transform: scale(1.2);
      }
    }

    &:active {
      transform: scale(1.05);
    }
  } */

  .textbox {
    h4 {
      @include media-breakpoint-up(sm) {
        height: 55px;
      }
    }
  }
}

// Quiz

.quizsteps {
  .btn.btn-answer {
    font-weight: 400;
    height: 120px;
    text-transform: none;
    @include media-breakpoint-down(sm) {
      height: auto;
    }

    &.correct {
      background-color: $green !important;
    }
    &.wrong {
      background-color: red !important;
    }
  }
  .answer-section {
    background-color: $blue-light2;
  }

  .question-section {
    img {
      max-width: 425px;
      @include media-breakpoint-down(sm) {
        max-width: 270px;
      }
    }

    .question-progress {
      position: relative;

      .q-progress {
        display: inline-block;
        padding: 10px 20px;
        background: $black;
        transform: skewX(-15deg) translateY(-50%);
        position: absolute;
        left: 0;
        top: 50%;
        span {
          transform: skewX(15deg);
          display: inline-block;
        }
        @include media-breakpoint-down(sm) {
          padding: 10px;
          font-size: 1rem;
        }
      }

      .question-text {
        margin: 0 auto;
        width: 70%;
        @include media-breakpoint-down(sm) {
          width: 60%;
        }
      }
    }

    .question-progress-square {
      .sq {
        display: inline-block;
        //padding: 5px;
        margin: 5px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: transparent;
        position: relative;
        border: 1px solid $orange;

        &.active {
          background: $white;
        }

        &.correct {
          background-color: $green;
        }

        &.wrong {
          background-color: red;
        }
      }

      .wrong,
      .correct {
        &:before {
          left: 50%;
          top: 50%;
          width: 15px;
          height: 15px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .wrong {
    pointer-events: none;
    position: relative;
    //filter: brightness(60%);
    //border: 2px solid transparent !important;
    //background: linear-gradient(180deg, #cc515d 0%, #ffa9b2 139.2%) !important;

    //box-shadow: 0px 0px 10px 2px rgba(#9E1315, 0.2);

    &:before {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      background: url("/assets/images/icons/icon-cross.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }

  .correct {
    pointer-events: none;
    position: relative;
    //border: 2px solid transparent !important;
    //background: linear-gradient(180deg, #46b15d 0%, #a5ffb9 139.2%) !important;

    &:before {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      background: url("/assets/images/icons/icon-check.svg") no-repeat;
      background-size: 100%;
    }
  }
}

.memorygame {
}

.game-container {
  background-color: $blue-light2;
  border-radius: 10px;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  background: rgba($black, 0.5);
  width: 100%;
  height: 100%;
}

.overlay-content {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 4;
  background-color: $blue;
}

.puzzle-container {
  /*   @include media-breakpoint-down(md) {
    transform: scale(0.7) translate(-50%);
    transform-origin: 0 0;
    position: relative;
    left: 50%;

    top: 0;
  }

  @media all and (max-width: 435px) {
    transform: scale(0.58) translate(-50%);
    transform-origin: 0 0;
    position: relative;
    left: 50%;
    top: 0;
  } */

  .piece > div {
    border: 0 !important ;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  }

  &.true {
    .piece > div {
      border: 0 !important;
      box-shadow: none !important;
      pointer-events: none;
    }
  }
}
