@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad/myriadpro-blackcond-webfont.woff2") format("woff2"),
    url("../fonts/myriad/myriadpro-blackcond-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad/myriadpro-boldcond-webfont.woff2") format("woff2"),
    url("../fonts/myriad/myriadpro-boldcond-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/myriad/myriadpro-cond-webfont.woff2") format("woff2"),
    url("../fonts/myriad/myriadpro-cond-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@import "./variables.scss";
@import "./global.scss";
@import "views/Home/Home.scss";
@import "views/OptIn/OptIn.scss";
@import "views/Game/Games.scss";
@import "views/Game/Success/Success.scss";
@import "views/Nagrade/Nagrade.scss";
@import "views/Game/Games/MemoryGame/MemoryGame.scss";
