//Component Memory

.deck {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 1000px;
}

.card {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s;
  user-select: none;
  transform-style: preserve-3d;
  // margin: 0 0 1% 0;
  width: 25%;
  padding-top: 25%;
  border: 0;
  background-color: transparent;

  @include media-breakpoint-down(sm) {
    margin: 0 0 1% 0;
  }
}

:focus {
  outline: 1px hotpink dotted;
}

.card div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: 0.6s;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}
.card p {
  margin: 0;
  padding: 0;
}
.card img {
  max-width: 80%;
  max-height: 80%;
}
.card .front {
  //background-color: #ccc;
  color: #000;
}
.card .back {
  background: url("/assets/images/_jub/back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  cursor: pointer;
  color: #ccc;
}
.card .front,
.card.flipping,
.card.matched {
  transform: rotateY(180deg);
}
.card.matched .front {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
  animation-fill-mode: both;
  opacity: 0.3;
}

.options {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.options .card {
  opacity: 0.4;
  filter: grayscale(100%);
}
.options .card.selected {
  opacity: 1;
  filter: none;
}

.errors {
  color: #666;
}

.finish {
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finish .content {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  position: relative;
  color: #666;
  z-index: 1;
  border: 1px solid #000;
  box-shadow: inset 0 0 6px 2px rgba(0, 0, 0, 0.5);
}

.finish h1 {
  font-size: 1.6em;
}

.finish .content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  filter: blur(2px);
  z-index: -1;
}
.finish nav {
  justify-content: center;
  flex-wrap: wrap;
}
.finish nav button,
.finish nav .button {
  margin: 0 20px 10px;
}

.fadein {
  animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

/* Responsive */
@media all and (min-width: 480px) {
  .finish nav {
    flex-wrap: initial;
  }
  .finish nav button,
  .finish nav .button {
    margin: 0 20px;
  }
  .card {
  }
}
