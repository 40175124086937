.optin {
  .invalid-feedback {
    color: $orange;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    color: $orange;
    border-color: $orange;
  }

  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label {
    color: $white;
  }
}

@keyframes floatHat {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px) translateX(-50%);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px) translateX(-50%);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px) translateX(-50%);
  }
}
